import { storeToRefs } from "pinia";
import { computed } from "#imports";
import type { LoyaltyLevelType } from "~/types/LoyaltyLevels";
import useUserStore from "~/stores/user";
import { useLoyaltyLevelStore } from "~/stores/member";
export default function useUserPrivilege() {
  const userStore = useUserStore();
  const loyaltyLevelStore = useLoyaltyLevelStore();
  const { loyaltyLevelId } = storeToRefs(userStore);

  const currentPrivilegeLevel = computed<LoyaltyLevelType>(() => {
    const currentPrivilege = loyaltyLevelStore.data.find(
      (item) => +item.id === loyaltyLevelId.value
    );
    return currentPrivilege || ({} as LoyaltyLevelType);
  });
  const nextPrivilegeLevel = computed<LoyaltyLevelType>(() => {
    const nextLevel = currentPrivilegeLevel.value.rank + 1;
    const nextPrivilege = loyaltyLevelStore.data.find(
      (item) => +item.rank === nextLevel
    );
    return nextPrivilege || ({} as LoyaltyLevelType);
  });
  const prevPrivilegeLevel = computed<LoyaltyLevelType>(() => {
    const prevLevel = currentPrivilegeLevel.value.rank - 1;
    const prevPrivilege = loyaltyLevelStore.data.find(
      (item) => +item.rank === prevLevel
    );
    return prevPrivilege || ({} as LoyaltyLevelType);
  });
  const nextPrivilegeLevelMinimumReservation = computed(() => {
    if (!nextPrivilegeLevel.value.id) {
      return 0;
    }
    return isRegainingPrivilegeLevel.value
      ? nextPrivilegeLevel.value.regain.totalReservations
      : nextPrivilegeLevel.value.qualification.totalReservations;
  });

  const nextPrivilegeLevelMinimumSpend = computed(() => {
    if (!nextPrivilegeLevel.value.id) {
      return 0;
    }
    return isRegainingPrivilegeLevel.value
      ? nextPrivilegeLevel.value.regain.totalSpend
      : nextPrivilegeLevel.value.qualification.totalSpend;
  });

  const isRegainingPrivilegeLevel = computed(() => {
    return userStore.topLoyaltyLevelId > userStore.loyaltyLevelId;
  });
  const isOnMaxPrivilegeLevel = computed(() => {
    if (!loyaltyLevelStore.data.length) {
      return false;
    }
    return (
      currentPrivilegeLevel.value.id ===
      loyaltyLevelStore.data[loyaltyLevelStore.data.length - 1].id
    );
  });

  return {
    currentPrivilegeLevel,
    nextPrivilegeLevel,
    prevPrivilegeLevel,
    nextPrivilegeLevelMinimumSpend,
    isRegainingPrivilegeLevel,
    nextPrivilegeLevelMinimumReservation,
    isOnMaxPrivilegeLevel,
  };
}
